<footer class="footer-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
<!--                <div class="single-footer-widget">-->
<!--                    <div class="footer-heading">-->
<!--                        <h3>About Us</h3>-->
<!--                    </div>-->
<!--                    <p>We are a group of software developers who are passionate about what we do</p>-->
<!--                    <ul class="footer-social">-->
<!--                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>-->
<!--                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>-->
<!--                        <li><a href="#" target="_blank"><i class="fab fa-pinterest"></i></a></li>-->
<!--                        <li><a href="#" target="_blank"><i class="fab fa-linkedin"></i></a></li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
<!--                <div class="single-footer-widget">-->
<!--                    <div class="footer-heading">-->
<!--                        <h3>Important Links</h3>-->
<!--                    </div>-->
<!--                    <ul class="footer-quick-links">-->
<!--                        <li><a routerLink="/about">About Us</a></li>-->
<!--                        <li><a routerLink="/projects">Project</a></li>-->
<!--                        <li><a routerLink="/services">Services</a></li>-->
<!--                        <li><a routerLink="/team">Team</a></li>-->
<!--                        <li><a routerLink="/contact">Contact</a></li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>
            <!-- <div class="col-lg-8 col-md-6 col-sm-6"> -->
               <!-- <div class="single-footer-widget">-->
<!--                    <div class="footer-heading">-->
<!--                        <h3>Featured Service</h3>-->
<!--                    </div>-->
<!--                    <ul class="footer-quick-links">-->
<!--                        <li><a routerLink="/">Home</a></li>-->
<!--                        <li><a routerLink="/blog-grid">Blog</a></li>-->
<!--                        <li><a routerLink="/services">Services</a></li>-->
<!--                        <li><a routerLink="/partner">Client</a></li>-->
<!--                        <li><a routerLink="/contact">Contact</a></li>-->
<!--                    </ul>-->
<!--                </div> -->
                    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d539.9254781072773!2d79.85464020995681!3d6.90127398417308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2592d4d5019bb%3A0x23534bbb988fb504!2sQbitum%20Solution%20(Pvt)%20Ltd.!5e0!3m2!1sen!2slk!4v1685418467142!5m2!1sen!2slk" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
            <!-- </div> -->
            <!-- <div class="col-lg-4 col-md-6 col-sm-6"> -->
            <div>
                <div class="single-footer-widget">
                    <div class="footer-heading" style="padding: 0px 40px;">
                        <h3>Contact</h3>
                    </div>
                    <div class="row" style="padding: 40px;">
                        <div class="footer-info-contact col-lg-4">
                            <a href="https://lk.linkedin.com/company/qbitum" target="_blank"><i class="fab fa-linkedin"></i></a>
                            <h3>Reach us</h3>
                            <span><a href="https://lk.linkedin.com/company/qbitum" target="_blank">Linkedin</a></span>
                        </div>
                        <div class="footer-info-contact col-lg-4">
                            <i class="flaticon-envelope"></i>
                            <h3>Email</h3>
                            <span><a href="mailto:info@qbitum.com">info@qbitum.com</a></span>
                        </div>
                        <div class="footer-info-contact col-lg-4">
                            <i class="flaticon-maps-and-flags"></i>
                            <h3>Address</h3>
                            <span>Qbitum Solution (Pvt) Ltd.<br> No.7/6, Bagatalle Road, Colombo 03, Sri Lanka.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div> -->
    <!-- <div class="partner-shape-img1">
        <img src="assets/img/shape/partnar-shape-2.png" alt="image">
    </div> -->
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <p><i class="far fa-copyright"></i>2022 Qbitum. All Rights Reserved</p>
            </div>
            <div class="col-lg-6 col-md-6">
                <ul>
<!--                    <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>-->
<!--                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>-->
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>

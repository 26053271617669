<div class="main-banner-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h1>Applying Data Science & Analytics To Life</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis Ipsum is simply dummy text of the printing.</p>
                            <div class="banner-btn">
                                <a routerLink="/about" class="default-btn-one">About Us <span></span></a>
                                <a href="https://www.youtube.com/watch?v=_ysd-zHamjk" class="video-btn popup-youtube"><i class="fa fa-play"></i> Watch Video </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/home-four/main-image.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="creative-shape"><img src="assets/img/main-banner-shape/home-shape-4.png" alt="main-image"></div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h3>Expolre Our Data Services</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-big-data"></i>
                    </div>
                    <h3>Big Data</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data-analytics"></i>
                    </div>
                    <h3>Data Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-document"></i>
                    </div>
                    <h3>Managed Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-chart"></i>
                    </div>
                    <h3>Business Intelligence</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-science"></i>
                    </div>
                    <h3>Data Science</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data-management"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h3>Focused on Actionable Insights</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <ul class="about-list">
                        <li><i class="flaticon-tick"></i> Professional Consultancy Service</li>
                        <li><i class="flaticon-tick"></i> 24/7 Support Center</li>
                        <li><i class="flaticon-tick"></i> Custom Service & Operation</li>
                        <li><i class="flaticon-tick"></i> Advanced Advisory Team</li>
                    </ul>
                    <div class="about-btn">
                        <a class="default-btn" routerLink="/about">Read More <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="820">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Data Analytics</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="150">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Data Management</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="20">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Managed  Analytics</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="120">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Big Data</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tab-section ptb-100">
    <div class="container">
        <div class="tab solutions-list-tab">
            <ul class="tabs">
                <li><a href="#"><span>Research & Solution</span></a></li>
                <li><a href="#"><span>Design & Strategy</span></a></li>
                <li><a href="#"><span>Analytics AI</span></a></li>
                <li><a href="#"><span>Cloud Deployment</span></a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>Enterprise AI & Data Platform Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <ul class="tab-list">
                                    <li><i class="flaticon-tick-1"></i> Innovation idea the latest business technology</li>
                                    <li><i class="flaticon-tick-1"></i> Safe Secure Services for your online email account</li>
                                    <li><i class="flaticon-tick-1"></i> Digital Content Marketing online</li>
                                </ul>
                                <div class="tab-btn">
                                    <a class="default-btn" href="#">Read More <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab-solution.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>Enterprise AI & Data Platform Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <ul class="tab-list">
                                    <li><i class="flaticon-tick-1"></i> Innovation idea the latest business technology</li>
                                    <li><i class="flaticon-tick-1"></i> Safe Secure Services for your online email account</li>
                                    <li><i class="flaticon-tick-1"></i> Digital Content Marketing online</li>
                                </ul>
                                <div class="tab-btn">
                                    <a class="default-btn" href="#">Read More <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab-solution.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>Enterprise AI & Data Platform Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <ul class="tab-list">
                                    <li><i class="flaticon-tick-1"></i> Innovation idea the latest business technology</li>
                                    <li><i class="flaticon-tick-1"></i> Safe Secure Services for your online email account</li>
                                    <li><i class="flaticon-tick-1"></i> Digital Content Marketing online</li>
                                </ul>
                                <div class="tab-btn">
                                    <a class="default-btn" href="#">Read More <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab-solution.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>Enterprise AI & Data Platform Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <ul class="tab-list">
                                    <li><i class="flaticon-tick-1"></i> Innovation idea the latest business technology</li>
                                    <li><i class="flaticon-tick-1"></i> Safe Secure Services for your online email account</li>
                                    <li><i class="flaticon-tick-1"></i> Digital Content Marketing online</li>
                                </ul>
                                <div class="tab-btn">
                                    <a class="default-btn" href="#">Read More <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab-solution.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="software-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="software-content">
                    <span>Our Working Process</span>
                    <h2>Software Developement Services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices Lorem Ipsum is simply dummy text.</p>
                    <ul class="features-list">
                        <li><span>Software Development</span></li>
                        <li><span>Automatic Optimization</span></li>
                        <li><span>Applicatication Services</span></li>
                        <li><span>IT Security Services</span></li>
                        <li><span>Data Analytics</span></li>
                        <li><span>System Integration Service</span></li>
                        <li><span>Help Desk Services</span></li>
                        <li><span>API Integration Service</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="software-image">
                    <img src="assets/img/software.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-section ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h3>What Our Client Say</h3>
        </div>
        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Richard Denial</h3>
                    <span>IT Executive</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/2.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Sarah Taylor</h3>
                    <span>Lead Architecure</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Alastair Cook</h3>
                    <span>Marketing Manager</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="testimonial-shape"><img src="assets/img/shape/testimonial-shape-1.png" alt="main-image"></div>
    <div class="testimonial-shape-img1"><img src="assets/img/shape/testimonial-shape-2.png" alt="image"></div>
    <div class="testimonial-shape-img2"><img src="assets/img/shape/testimonial-shape-3.png" alt="image"></div>
</section>

<section class="productive-section pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="productive-content">
                    <span>Let’s Get Started</span>
                    <h3>Are you ready for a better, more productive business </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices Lorem Ipsum is simply dummy text.</p>
                    <div class="productive-btn">
                        <a routerLink="/projects" class="productive-btn">Get Started Project<span></span></a>
                        <a routerLink="/contact" class="productive-btn-one">Contact With Us<span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="productive-image">
                    <img src="assets/img/productive.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Blog</span>
            <h3>Recent Blog</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/3.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 9 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 4 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Startup Marketing Solution for Business Owner</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/4.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 8 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 4 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Web Developement Best Work in Future World</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/5.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 7 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 3 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Creative solutions to improve your business!</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="contact-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-image">
                    <img src="assets/img/contact.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="submit-btn">Submit Now!</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="partner-area">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/2.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/3.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/4.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/5.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/6.png" alt="partner"></a>
            </div>
        </div>
    </div>
</div>
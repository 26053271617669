<div class="page-title-area item-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Products and Services</h2>
<!--                    <ul>-->
<!--                        <li><a routerLink="/">Home</a></li>-->
<!--                        <li>Products & Services</li>-->
<!--                    </ul>-->
                </div>
            </div>
        </div>
    </div>
    <div class="page-title-bg-service"></div>
</div>

<section class="software-section pb-100">
    <div class="container">
    </div>
</section>

<section class="software-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="software-content">
<!--                <span>Data Centric Software Solutions</span>-->
                    <h2>Explore Our Data Services</h2>
                    <p>The Multi-disciplinary and Inter-disciplinary nature of Modern Data Science makes it hard to cover the breadth and depth of it. Data science uses scientific methods, methodical processes, algorithms, software and tools and techniques. Our team has skills and experience around following core concepts</p>
                    <ul class="features-list">
                        <li><span>Anomaly Detection</span></li>
                        <li><span>Predictive Analytics</span></li>
                        <li><span>Risk Profiling</span></li>
                        <li><span>Service Personalization</span></li>
                        <li><span>Operational Insights</span></li>
                        <li><span>Data Exploration</span></li>
                        <li><span>Data Engineering</span></li>
                        <li><span>Big Data Analytics</span></li>
                        <li><span>Machine Learning Modeling</span></li>
                        <li><span>Data Capturing</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="software-image">
                    <img src="assets/img/explore_data_s.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="software-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-lg-last">
                <div class="software-content">
<!--                    <span>Our Working Process</span>-->
                    <h2>Software Development Services</h2>
                    <p>Vast breadth of Cloud technologies and associated continuous deployments and platform engineering makes it hard to cover the breadth and depth of it. Our team has skills and experience around following core concept</p>
                    <ul class="features-list">
                        <li><span>Product Development</span></li>
                        <li><span>Hybrid Apps</span></li>
                        <li><span>Single Page Web Apps</span></li>
                        <li><span>REST APIs</span></li>
                        <li><span>Containerization</span></li>
                        <li><span>Stream Processing</span></li>
                        <li><span>Microservices</span></li>
                        <li><span>Cloud Native systems</span></li>
                        <li><span>Event Driven systems</span></li>
                        <li><span>Monitoring</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-lg-first">
                <div class="software-image">
                    <img src="assets/img/software_dev_s.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>


<!--
<section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-big-data"></i>
                    </div>
                    <h3>Big Data</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data-analytics"></i>
                    </div>
                    <h3>Data Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-document"></i>
                    </div>
                    <h3>Managed Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-chart"></i>
                    </div>
                    <h3>Business Intelligence</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-science"></i>
                    </div>
                    <h3>Data Science</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data-management"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="software-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="software-content">
                    <span>Our Working Process</span>
                    <h2>Software Developement Services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices Lorem Ipsum is simply dummy text.</p>
                    <ul class="features-list">
                        <li><span>Software Development</span></li>
                        <li><span>Automatic Optimization</span></li>
                        <li><span>Applicatication Services</span></li>
                        <li><span>IT Security Services</span></li>
                        <li><span>Data Analytics</span></li>
                        <li><span>System Integration Service</span></li>
                        <li><span>Help Desk Services</span></li>
                        <li><span>API Integration Service</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="software-image">
                    <img src="assets/img/software.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-section pb-100">
    <div class="container">
        <div class="subscribe-content-area mb-0">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-image">
                        <img src="assets/img/subscribe.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-content">
                        <h2>Don’t Miss Our News And Updates</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button type="submit">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>


-->

<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo_1.png" class="white-logo" alt="logo" style="width: 74%;">
                <img src="assets/img/logo.png" class="black-logo" alt="logo" style="width: 60%;">
                <!-- <h1 style="color: white; font-size: 3em;"><span style="color: white;">Qbitum</span>
                </h1> -->
            </a>
            
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home </a></li>

                    <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                    <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products & Services </a></li>

                    <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/careers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Careers </a></li>

                    <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                </ul>

                <!-- <div class="other-option">
                    <a class="default-btn" routerLink="/contact">Free Quote <span></span></a>
                </div> -->
            </div>
        </nav>
    </div>
</div>
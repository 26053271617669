<div class="page-title-area item-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog Grid</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog Grid</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/3.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 9 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 4 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Startup Marketing Solution for Business Owner</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/4.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 8 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 4 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Web Developement Best Work in Future World</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/5.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 7 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 3 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Creative solutions to improve your business!</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/4.png" alt="image"></a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i>7 Dec, 2020</a></li>
                            <li><i class="far fa-comments"></i> 3 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>  
                </div> 
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/5.png" alt="image"></a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 7 Dec, 2020</a></li>
                            <li><i class="far fa-comments"></i> 3 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Instagram Feed Add To Your WordPress Site</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>  
                </div> 
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/6.png" alt="image"></a>
                    </div>

                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 7 Dec, 2020</a></li>
                            <li><i class="far fa-comments"></i> 3 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <div class="blog-btn">
                                    <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div> 
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog-grid" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a routerLink="/blog-grid" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/blog-grid" class="page-numbers">3</a>
                    <a routerLink="/blog-grid" class="page-numbers">4</a>
                    <a routerLink="/blog-grid" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
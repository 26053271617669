<div class="main-banner-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h1>Data Science as a Service</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis Ipsum is simply dummy text of the printing.</p>
                            <div class="banner-btn">
                                <a class="default-btn-one" routerLink="/about">About Us <span></span></a>
                                <a class="default-btn" routerLink="/pricing">Contact Us <span></span></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/home-three/1.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/2.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/3.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/4.png" class="wow zoomIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/5.png" class="wow bounceIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/6.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/7.png" class="wow zoomIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/8.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/9.png" class="wow rotateIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/10.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/11.png" class="wow zoomIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-three/main-image.png" class="wow zoomIn" data-wow-delay="0.12s" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="creative-shape"><img src="assets/img/main-banner-shape/home-shape-3.png" alt="main-image"></div>
    <div class="shape-img1"><img src="assets/img/main-banner-shape/2.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/main-banner-shape/3.png" alt="image"></div>
</div>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h3>Focused on Actionable Insights</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <ul class="about-list">
                        <li><i class="flaticon-tick"></i> Professional Consultancy Service</li>
                        <li><i class="flaticon-tick"></i> 24/7 Support Center</li>
                        <li><i class="flaticon-tick"></i> Custom Service & Operation</li>
                        <li><i class="flaticon-tick"></i> Advanced Advisory Team</li>
                    </ul>
                    <div class="about-btn">
                        <a class="default-btn" routerLink="/about">Read More <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="data-service-section pb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h3>Explore Our Data Services</h3>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <div class="data-services-item">
                    <div class="single-data-service-box"> 
                        <div class="icon">
                            <i class="flaticon-big-data"></i>
                        </div>
                        <h3><a routerLink="/services-details">Big Data <i class="flaticon-right"></i></a></h3>
                    </div>
                    <div class="single-data-service-box"> 
                        <div class="icon">
                            <i class="flaticon-science"></i>
                        </div>
                        <h3><a routerLink="/services-details">Data Science <i class="flaticon-right"></i></a></h3>
                    </div>
                    <div class="single-data-service-box"> 
                        <div class="icon">
                            <i class="flaticon-document"></i>
                        </div>
                        <h3><a routerLink="/services-details">Managed Analytics <i class="flaticon-right"></i></a></h3>
                    </div>
                </div>   
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="data-service-image">
                    <img src="assets/img/data-service.png" alt="image">
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="data-services-item">
                    <div class="single-data-service-box"> 
                        <div class="icon">
                            <i class="flaticon-data-analytics"></i>
                        </div>
                        <h3><a routerLink="/services-details">Data Analytics <i class="flaticon-right"></i></a></h3>
                    </div>
                    <div class="single-data-service-box"> 
                        <div class="icon">
                            <i class="flaticon-chart"></i>
                        </div>
                        <h3><a routerLink="/services-details">Business Intelligence <i class="flaticon-right"></i></a></h3>
                    </div>
                    <div class="single-data-service-box"> 
                        <div class="icon">
                            <i class="flaticon-data"></i>
                        </div>
                        <h3><a routerLink="/services-details">Data Management <i class="flaticon-right"></i></a></h3>
                    </div>
                </div>   
            </div>
        </div>
    </div>
</section>

<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="820">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Data Analytics</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="150">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Data Management</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="20">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Managed  Analytics</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="120">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Big Data</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tab-section ptb-100">
    <div class="container">
        <div class="tab solutions-list-tab">
            <ul class="tabs">
                <li><a href="#"><span>Research & Solution</span></a></li>
                <li><a href="#"><span>Design & Strategy</span></a></li>
                <li><a href="#"><span>Analytics AI</span></a></li>
                <li><a href="#"><span>Cloud Deployment</span></a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>Enterprise AI & Data Platform Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <ul class="tab-list">
                                    <li><i class="flaticon-tick-1"></i> Innovation idea the latest business technology</li>
                                    <li><i class="flaticon-tick-1"></i> Safe Secure Services for your online email account</li>
                                    <li><i class="flaticon-tick-1"></i> Digital Content Marketing online</li>
                                </ul>
                                <div class="tab-btn">
                                    <a class="default-btn" href="#">Read More <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab-solution.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>Enterprise AI & Data Platform Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <ul class="tab-list">
                                    <li><i class="flaticon-tick-1"></i> Innovation idea the latest business technology</li>
                                    <li><i class="flaticon-tick-1"></i> Safe Secure Services for your online email account</li>
                                    <li><i class="flaticon-tick-1"></i> Digital Content Marketing online</li>
                                </ul>
                                <div class="tab-btn">
                                    <a class="default-btn" href="#">Read More <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab-solution.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>Enterprise AI & Data Platform Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <ul class="tab-list">
                                    <li><i class="flaticon-tick-1"></i> Innovation idea the latest business technology</li>
                                    <li><i class="flaticon-tick-1"></i> Safe Secure Services for your online email account</li>
                                    <li><i class="flaticon-tick-1"></i> Digital Content Marketing online</li>
                                </ul>
                                <div class="tab-btn">
                                    <a class="default-btn" href="#">Read More <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab-solution.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="tab-solution-content">
                                <h3>Enterprise AI & Data Platform Solutions</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <ul class="tab-list">
                                    <li><i class="flaticon-tick-1"></i> Innovation idea the latest business technology</li>
                                    <li><i class="flaticon-tick-1"></i> Safe Secure Services for your online email account</li>
                                    <li><i class="flaticon-tick-1"></i> Digital Content Marketing online</li>
                                </ul>
                                <div class="tab-btn">
                                    <a class="default-btn" href="#">Read More <span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="tab-image">
                                <img src="assets/img/tab-solution.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="project-section pb-70">
    <div class="container">
        <div class="section-title">
            <span>Project</span>
            <h3>Our Latest Project</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/project/1.png" alt="image">
                    </div>
                    <div class="image-hover">
                        <div class="text">
                            <h3>Data is the new science big data holds the answer</h3>
                            <a routerLink="/projects-details" class="read-more">Data Analysis</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/project/2.png" alt="image">
                    </div>
                    <div class="image-hover">
                        <div class="text">
                            <h3>Data is the new science big data holds the answer</h3>
                            <a routerLink="/projects-details" class="read-more">Business Intelligence</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/project/3.png" alt="image">
                    </div>
                    <div class="image-hover">
                        <div class="text">
                            <h3>Data is the new science big data holds the answer</h3>
                            <a routerLink="/projects-details" class="read-more">Data Management</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-section ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h3>What Our Client Say</h3>
        </div>
        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Richard Denial</h3>
                    <span>IT Executive</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/2.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Sarah Taylor</h3>
                    <span>Lead Architecure</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Alastair Cook</h3>
                    <span>Marketing Manager</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="testimonial-shape"><img src="assets/img/shape/testimonial-shape-1.png" alt="main-image"></div>
    <div class="testimonial-shape-img1"><img src="assets/img/shape/testimonial-shape-2.png" alt="image"></div>
    <div class="testimonial-shape-img2"><img src="assets/img/shape/testimonial-shape-3.png" alt="image"></div>
</section>

<section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Blog</span>
            <h3>Recent Blog</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/3.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 9 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 4 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Startup Marketing Solution for Business Owner</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/4.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 8 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 4 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Web Developement Best Work in Future World</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/5.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 7 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 3 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Creative solutions to improve your business!</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-section">
    <div class="container">
        <div class="subscribe-content-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-image">
                        <img src="assets/img/subscribe.png" alt="image">     
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-content">
                        <h2>Don’t Miss Our News And Updates</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button type="submit">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-section pt-100 pb-70">
    <div class="container">
        <div class="partner-title">
            <span>Customer</span>
            <h3>Discover Customer Successful Stories</h3>
        </div>
        <div class="partner-list">
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/2.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/3.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/4.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/5.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/6.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/7.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/8.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="image"></a>
            </div>
        </div>
    </div>

    <div class="partner-shape"><img src="assets/img/shape/partnar-shape-1.png" alt="main-image"></div>
    <div class="partner-shape-img1"><img src="assets/img/shape/partnar-shape-2.png" alt="image"></div>
</section>
<section class="subscribe-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="subscribe-content">
                    <h2>Sign Up For Newsletter</h2>
                    <span class="sub-title">And Receive 40% Discount On First Project</span>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                    <button type="submit">Subscribe Now</button>
                </form>
            </div>
        </div>
    </div>
</section>
<div class="page-title-area item-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Products</h2>
                    <!--                    <ul>-->
                    <!--                        <li><a routerLink="/">Home</a></li>-->
                    <!--                        <li>Products & Services</li>-->
                    <!--                    </ul>-->
                </div>
            </div>
        </div>
    </div>
    <div class="page-title-bg-service"></div>
</div>

<section class="software-section pb-100">
    <div class="container">
    </div>
</section>

<!-- <section class="software-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-lg-last">
                <div class="software-content">

                    <h2>Software Development Services</h2>
                    <p>Vast breadth of Cloud technologies and associated continuous deployments and platform engineering makes it hard to cover the breadth and depth of it. Our team has skills and experience around following core concept</p>
                    <ul class="features-list">
                        <li><span>Product Development</span></li>
                        <li><span>Hybrid Apps</span></li>
                        <li><span>Single Page Web Apps</span></li>
                        <li><span>REST APIs</span></li>
                        <li><span>Containerization</span></li>
                        <li><span>Stream Processing</span></li>
                        <li><span>Microservices</span></li>
                        <li><span>Cloud Native systems</span></li>
                        <li><span>Event Driven systems</span></li>
                        <li><span>Monitoring</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-lg-first">
                <div class="software-image">
                    <img src="assets/img/software_dev_s.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="software-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="software-content">
                    <h2>Inspector Bots</h2>
                    <p class="highlight-para">In the world of Inspections
                        Multiple Industries and Trades put
                        Enormous burden on their employees to do
                        Strenuous, Exhaustive and Tedious Inspections
                        <br>
                        This adds major risks to the business and hinders business potential and scaling
                        <br>
                        It demands employees to do humanly incapable and unsuitable work as their core job
                        Due to lack of solutions and tools both the Business and the Employees suffer
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="software-image">
                    <img src="assets/img/co-bot.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-section pb-100 pt-100">
    <div class="container">
        <div class="section-title">
            <!-- <span>Testimonial</span> -->
            <h3>What Our Client Say</h3>
        </div>
        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-single-item">
                <!-- <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div> -->
                <div class="client-info text-center">
                    <div class="testimonial-info w-100 text-center">
                        <!-- <span class="tm-org-txt">SolTrim International (Pvt) Ltd</span> -->
                        <div class="w-100 text-center">
                            <img src="assets/img/client/st-logo.png" alt="image" style="margin: auto;">
                        </div>

                        <hr class="solid" style="margin: auto;">
                    </div>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>
                        Printing industry quality inspections are mostly manual. The accuracy,speed, efficiency and the
                        overall cost has been challenged by these manual operations.
                        <br>
                        Manual inspections are tedious and require our quality inspectors to focus on very small prints
                        at a fast pace throughout the day that wears them out very fast.
                        <br>
                        The artificial intelligence (AI) and computer vision based solution for quality inspections that
                        "Qbitum Accumetic" product has featured is something our industry has been waiting for last 20
                        years.
                        <br>
                        Looking at the results of the case studies we have tried so far, we are positive that "Qbitum
                        Accumetic" is a very promising solution.
                    </p>
                </div>
                <div class="client-info text-center mt-2">
                    <div class="testimonial-info w-100 text-center" style="margin: 20px 0;">
                        <!-- <span class="tm-org-txt">SolTrim International (Pvt) Ltd</span> -->
                        <hr class="solid" width="200px" style="margin: auto;">
                        <div class="text-center mb-0" style="font-weight: bold; margin-top: 16px;">Suren Abeyasekera
                        </div>
                        <span class="text-center" style="font-size: .8rem;">Chief Executive Officer</span>
                    </div>
                </div>
            </div>
            <!-- <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/2.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Sarah Taylor</h3>
                    <span>Lead Architecure</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Alastair Cook</h3>
                    <span>Marketing Manager</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div> -->
        </div>
    </div>

    <!-- <div class="testimonial-shape"><img src="assets/img/shape/testimonial-shape-1.png" alt="main-image"></div> -->
    <!-- <div class="testimonial-shape-img1"><img src="assets/img/shape/testimonial-shape-2.png" alt="image"></div> -->
    <!-- <div class="testimonial-shape-img2"><img src="assets/img/shape/testimonial-shape-3.png" alt="image"></div> -->
</section>

<section class="software-section mt-100" style="background-color: #713E97; padding: 6rem 0;">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
                <div class="software-content">
                    <h2 class="text-center" style="color: #fff;">Our Mission</h2>
                    <p class="text-center" style="color: #fff; font-size: 1.6rem;">Automate Strenuous, Exhaustive and
                        Tedious Human work
                        with the use of AI and
                        other emerging
                        solutions like digital twin and Industry 5.0</p>
                </div>
            </div>
            <!-- <div class="col-lg-12 text-center">
                <div class="software-image">
                    <img src="assets/img/software.png" alt="image">
                </div>
            </div> -->
        </div>
    </div>
</section>

<section class="software-section pb-100 pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12">
                <div>
                    <img src="assets/img/2-step-prosess.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <!-- <div class="col-lg-6 col-md-12">
                <div class="software-image text-center">
                    <img src="assets/img/multidisciplinary_teams.png" alt="image">
                </div>
            </div> -->
            <div class="col-lg-6">
                <div class="about-content">
                    <!-- <span>About Us</span> -->
                    <h2>Current Co-Bots by Industry</h2>
                    <br>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> -->
                    <div>
                        <div class="row">
                            <div>
                                <ul class="font-2x">
                                    <li class="web-defult-font">Printing Industry - Label
                                    </li>

                                    <li class="web-defult-font">Apparel Industry
                                    </li>

                                    <li class="web-defult-font">Printing Industry - Packaging Materials</li>

                                    <li class="web-defult-font">Embroidery Industry
                                    </li>

                                    <li class="web-defult-font">Printing Industry - Garments
                                    </li>

                                    <li class="web-defult-font">Fabric Industry
                                    </li>

                                    <li class="web-defult-font">Apparel Pattern and Design
                                        Industry
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <!-- <div class="about-btn">
                        <a class="default-btn" routerLink="/about">Read More <span></span></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-big-data"></i>
                    </div>
                    <h3>Big Data</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i
                                class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data-analytics"></i>
                    </div>
                    <h3>Data Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i
                                class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-document"></i>
                    </div>
                    <h3>Managed Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i
                                class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-chart"></i>
                    </div>
                    <h3>Business Intelligence</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i
                                class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-science"></i>
                    </div>
                    <h3>Data Science</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i
                                class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data-management"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i
                                class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="subscribe-section pb-100">
    <div class="container">
        <div class="subscribe-content-area mb-0">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-image">
                        <img src="assets/img/subscribe.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-content">
                        <h2>Don’t Miss Our News And Updates</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button type="submit">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->
<div class="page-title-area item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/project/1.png" alt="image">
                    </div>
                    <div class="image-hover">
                        <div class="text">
                            <h3>Data is the new science big data holds the answer</h3>
                            <a routerLink="/projects-details" class="read-more">Data Analysis</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/project/2.png" alt="image">
                    </div>
                    <div class="image-hover">
                        <div class="text">
                            <h3>Data is the new science big data holds the answer</h3>
                            <a routerLink="/projects-details" class="read-more">Business Intelligence</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/project/3.png" alt="image">
                    </div>
                    <div class="image-hover">
                        <div class="text">
                            <h3>Data is the new science big data holds the answer</h3>
                            <a routerLink="/projects-details" class="read-more">Data Management</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/project/4.png" alt="image">
                    </div>
                    <div class="image-hover">
                        <div class="text">
                            <h3>Data is the new science big data holds the answer</h3>
                            <a routerLink="/projects-details" class="read-more">Data Analysis</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/project/5.png" alt="image">
                    </div>
                    <div class="image-hover">
                        <div class="text">
                            <h3>Data is the new science big data holds the answer</h3>
                            <a routerLink="/projects-details" class="read-more">Business Intelligence</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-project">
                    <div class="project-image">
                        <img src="assets/img/project/6.png" alt="image">
                    </div>
                    <div class="image-hover">
                        <div class="text">
                            <h3>Data is the new science big data holds the answer</h3>
                            <a routerLink="/projects-details" class="read-more">Data Management</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-section pt-100 pb-70">
    <div class="container">
        <div class="partner-title">
            <span>Customer</span>
            <h3>Discover Customer Successful Stories</h3>
        </div>
        <div class="partner-list">
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/2.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/3.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/4.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/5.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/6.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/7.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/8.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="image"></a>
            </div>
        </div>
    </div>

    <div class="partner-shape"><img src="assets/img/shape/partnar-shape-1.png" alt="main-image"></div>
    <div class="partner-shape-img1"><img src="assets/img/shape/partnar-shape-2.png" alt="image"></div>
</section>

<app-subscribe></app-subscribe>
<div class="page-title-area item-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Pricing</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Pricing</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Basic</h3>
                        <p>That is a long established fact that a reader will be distracted.</p>
                    </div>
                    <div class="price">
                        $29<span>/month</span>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="fas fa-check"></i> Managed Analytics</li>
                        <li><i class="fas fa-check"></i> Business Intelligence
                        </li><li><i class="fas fa-check"></i> International payments</li>
                        <li><i class="fas fa-check"></i> Data Management</li>
                        <li><i class="fas fa-check"></i> Social Integration</li>
                        <li><i class="fas fa-check"></i> Relevant App Content</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="price-btn-one">Choose this plan</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Advanced</h3>
                        <p>That is a long established fact that a reader will be distracted.</p>
                    </div>
                    <div class="price">
                        $59<span>/month</span>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="fas fa-check"></i> Managed Analytics</li>
                        <li><i class="fas fa-check"></i> Business Intelligence
                        </li><li><i class="fas fa-check"></i> International payments</li>
                        <li><i class="fas fa-check"></i> Data Management</li>
                        <li><i class="fas fa-check"></i> Social Integration</li>
                        <li><i class="fas fa-check"></i> Relevant App Content</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="price-btn-one">Choose this plan</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                        <p>That is a long established fact that a reader will be distracted.</p>
                    </div>
                    <div class="price">
                        $99<span>/month</span>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="fas fa-check"></i> Managed Analytics</li>
                        <li><i class="fas fa-check"></i> Business Intelligence</li>
                        <li><i class="fas fa-check"></i> International payments</li>
                        <li><i class="fas fa-check"></i> Data Management</li>
                        <li><i class="fas fa-check"></i> Social Integration</li>
                        <li><i class="fas fa-check"></i> Relevant App Content</li>
                    </ul>
                    <div class="price-btn">
                        <a routerLink="/" class="price-btn-one">Choose this plan</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-section pb-100">
    <div class="container">
        <div class="subscribe-content-area mb-0">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-image">
                        <img src="assets/img/subscribe.png" alt="image">     
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-content">
                        <h2>Don’t Miss Our News And Updates</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button type="submit">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
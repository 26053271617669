<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Careers</h2>
                    <!--                    <ul>-->
                    <!--                        <li><a routerLink="/">Home</a></li>-->
                    <!--                        <li>About Us</li>-->
                    <!--                    </ul>-->
                    <!-- <img src="assets/img/pages_banners_1.png" class="page-title-bg" alt="image"> -->
                </div>
            </div>
        </div>
    </div>
    <div class="page-title-bg-careers"></div>
</div>
<p><BR /></p>
<section class="about-section ptb-50">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <h3>Multiple Opportunities Available</h3>
                <div class="about-content careers-content">
                    <div class="job-category">
                        <div class="job-titles">
                            <div>Jr. / Sr. Project Managers</div>
                            <!-- <div>App Developers (iOS, Android, Hybrid), 
                            Sr./Jr. Software Engineers (Full Stack), 
                            Sr./Jr. Quality Assurance Engineers</div> -->
                        </div>
                        <!-- <div class="intern">Internships available</div> -->
                        <ul class="about-list">
                            <li><i class="flaticon-tick"></i> 1 to 3 years’ experience</li>
                            <li><i class="flaticon-tick"></i> Strong experience in Software Project Management, Client
                                facing and Agile development</li>
                            <li><i class="flaticon-tick"></i> Degree in Software Engineering, Computer Science,
                                Electronics Engineering or Equivalent</li>
                        </ul>

                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="about-content careers-content">
                                    <h4>What you will need</h4>
                                    <div>
                                        <ul class="about-list">
                                            <li>
                                                <i class="flaticon-tick"></i>Strong relationship-building skills with
                                                the ability to engage and influence others towards achieving team goals
                                            </li>
                                            <li><i class="flaticon-tick"></i> Knowledge and experience for applying
                                                project management techniques, skills and tools to project deliverables
                                            </li>
                                            <li><i class="flaticon-tick"></i> Ability to initiate, plan, execute, close
                                                and control each project on given deadlines</li>
                                            <li><i class="flaticon-tick"></i> Experience in working with external
                                                clients and managing and coordinating between external and internal
                                                teams</li>
                                            <li><i class="flaticon-tick"></i> Ability to take ownership in driving
                                                projects and achieving goals and producing deliverables</li>
                                            <li><i class="flaticon-tick"></i> Collect and analyze client business
                                                requirements and rules and align them with project plan</li>
                                            <li><i class="flaticon-tick"></i> Ability to directs business analysts,
                                                software developers, QA engineers and all other stakeholders to
                                                determine best methods to achieve project objectives</li>
                                            <li><i class="flaticon-tick"></i> Coordinate requirements, schedule
                                                implementation and post-implement plans, develop service-level
                                                agreements for clients and all other activities within the project to
                                                ensure that project tasks are completed on time</li>
                                            <li><i class="flaticon-tick"></i>Direct and manage project timelines from
                                                beginning to end, under no supervision</li>
                                            <li><i class="flaticon-tick"></i>Define quality gates for each project
                                                methodology and make sure project quality objectives are met</li>
                                            <li><i class="flaticon-tick"></i>Acts as subject matter expert for project
                                                and program management related tools and processes</li>
                                            <li><i class="flaticon-tick"></i>Ability to communicate and develop
                                                documentation, project plans, flowcharts, layouts, diagrams etc.</li>
                                            <li><i class="flaticon-tick"></i>Evaluate escalations, troubleshoot and
                                                advise on next steps to work collaboratively with internal and external
                                                teams</li>
                                            <li><i class="flaticon-tick"></i>Comfortable working in a fast-paced,
                                                dynamic environment</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="about-content careers-content">
                    <div class="job-category">
                        <div class="job-titles">
                            <div>App Developers</div>

                            <!-- <div>App Developers (iOS, Android, Hybrid), 
                            Sr./Jr. Software Engineers (Full Stack), 
                            Sr./Jr. Quality Assurance Engineers</div> -->
                        </div>
                        <div style="font-size: 20px; color: black;"><b>Flutter,
                                Hybrid / Ionic,

                                Android,
                                iOS</b></div>
                        <!-- <div class="intern">Internships available</div> -->
                        <ul class="about-list">
                            <li><i class="flaticon-tick"></i> 1 to 4 years’ experience</li>
                            <li><i class="flaticon-tick"></i> Strong knowledge in Software Engineering and Programming
                                principles and practices</li>
                            <li><i class="flaticon-tick"></i> Degree in Software Engineering, Computer Science,
                                Electronics Engineering or Equivalent</li>
                        </ul>

                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="about-content careers-content">
                                    <h4>What you will need</h4>
                                    <div>
                                        <ul class="about-list">
                                            <li>
                                                <i class="flaticon-tick"></i>Hands on relevant years of experience in
                                                application development and troubleshooting focusing on either Flutter,
                                                Ionic, Hybrid Apps, Android or iOS development
                                            </li>
                                            <li><i class="flaticon-tick"></i> Hands on experience and thorough knowledge
                                                in SQL and/or NoSQL databases
                                            </li>
                                            <li><i class="flaticon-tick"></i> Ability to collaborate with other
                                                developers, analysts, architects and project managers to plan, design,
                                                develop, test,
                                                and maintain applications</li>
                                            <li><i class="flaticon-tick"></i> Ability to communicate and develop
                                                solutions by developing documentation, flowcharts, layouts, diagrams,
                                                charts,
                                                code comments, and clear code</li>
                                            <li><i class="flaticon-tick"></i> Maintain high standards of software
                                                quality within the team by establishing good practices and habits.</li>
                                            <li><i class="flaticon-tick"></i> Take ownership in driving projects and
                                                achieving goals and producing deliverables</li>
                                            <li><i class="flaticon-tick"></i> Comfortable working in a fast-paced,
                                                dynamic environment</li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="about-content careers-content">
                                    <h4>Having the following skills is a plus</h4>
                                    <div>
                                        <ul class="about-list">
                                            <li>
                                                <i class="flaticon-tick"></i>Proven experience in troubleshooting and
                                                supporting business and production issues
                                            </li>
                                            <li><i class="flaticon-tick"></i> Strong Experience in REST, JSON,
                                                API/Services, WebSockets
                                            </li>
                                            <li><i class="flaticon-tick"></i> Experience in Angular, Ionic, TypeScript,
                                                JavaScript, HTML5, CSS</li>
                                            <li><i class="flaticon-tick"></i>Modern web and mobile technologies - Single
                                                page web Apps and responsive Apps development</li>
                                            <li><i class="flaticon-tick"></i> Experience in concepts and libraries such
                                                as application state management (GetX etc.), Offline Support, App
                                                Security</li>
                                            <li><i class="flaticon-tick"></i> IOT Applications, frameworks and
                                                technologies</li>
                                            <li><i class="flaticon-tick"></i> JAVA skills, Asynchronous programming,
                                                RESTful Microservices</li>

                                            <li><i class="flaticon-tick"></i> Understanding of asynchronous Event-driven
                                                systems, Reactive programming, Functional Programming</li>

                                            <li><i class="flaticon-tick"></i> Play Framework, Spring Framework and other
                                                java related web technologies and frameworks</li>

                                            <li><i class="flaticon-tick"></i> PostgreSQL, MongoDB, Cassandra,
                                                Elasticsearch, OLAP, Redis, Caching technologies and libraries</li>

                                            <li><i class="flaticon-tick"></i> Experience with dev tools like Git, GitHub
                                                Actions, Maven, Gulp, SBT, Jenkins other build/deployment tools and JIRA
                                            </li>

                                            <li><i class="flaticon-tick"></i> Unit testing and Test-Driven Development
                                            </li>
                                            <li><i class="flaticon-tick"></i> Knowledge of automated testing and test
                                                frameworks like Selenium etc.
                                            </li>
                                            <li><i class="flaticon-tick"></i> Knowledge of software development
                                                lifecycle and Agile software development
                                            </li>
                                            <li><i class="flaticon-tick"></i> Graylog, Prometheus, Grafana and other
                                                systems monitoring tools and techniques
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="about-content careers-content">
                    <div class="job-category">
                        <div class="job-titles">
                            <div>Cloud / DevOps Engineer</div>

                            <!-- <div>App Developers (iOS, Android, Hybrid), 
                            Sr./Jr. Software Engineers (Full Stack), 
                            Sr./Jr. Quality Assurance Engineers</div> -->
                        </div>
                        <div style="font-size: 20px; color: black;"><b>AWS / GCP / Azure / Docker / Kubernetes</b></div>
                        <!-- <div class="intern">Internships available</div> -->
                        <ul class="about-list">
                            <li><i class="flaticon-tick"></i> 2 to 5 years’ experience</li>
                            <li><i class="flaticon-tick"></i> Strong knowledge in Cloud Engineering, Containerization,
                                Performance tuning and Systems engineering</li>
                            <li><i class="flaticon-tick"></i> Degree in Software Engineering, Computer Science,
                                Electronics Engineering or Equivalent</li>
                        </ul>

                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="about-content careers-content">
                                    <h4>What you will need</h4>
                                    <div>
                                        <ul class="about-list">
                                            <li>
                                                <i class="flaticon-tick"></i>Hands on relevant years of experience on
                                                AWS/GCP/Azure or other similar systems
                                            </li>
                                            <li><i class="flaticon-tick"></i> Hands on experience and thorough knowledge
                                                of Containers, Docker and Kubernetes
                                            </li>
                                            <li><i class="flaticon-tick"></i> Hands on experience and knowledge systems
                                                monitoring tools and techniques</li>
                                            <li><i class="flaticon-tick"></i> Hands on experience and knowledge Dev Ops
                                                concepts, tools and techniques</li>
                                            <li><i class="flaticon-tick"></i> Hands on experience and knowledge of
                                                Operating systems</li>
                                            <li><i class="flaticon-tick"></i> THands on experience and knowledge of
                                                Security, Database administration and Networking</li>
                                            <li><i class="flaticon-tick"></i> Hands on experience and knowledge of
                                                scripting, coding for automations</li>
                                            <li><i class="flaticon-tick"></i> Take ownership in driving projects and
                                                achieving goals and producing deliverables</li>
                                            <li><i class="flaticon-tick"></i> Ability to collaborate with developers,
                                                architects and project managers to plan, design and maintain
                                                applications</li>
                                            <li><i class="flaticon-tick"></i> Ability to communicate and develop
                                                documentation, flowcharts, layouts, diagrams and clear code</li>
                                            <li><i class="flaticon-tick"></i> Comfortable working in a fast-paced,
                                                dynamic environment</li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="about-content careers-content">
                                    <h4>Having the following skills is a plus</h4>
                                    <div>
                                        <ul class="about-list">
                                            <li>
                                                <i class="flaticon-tick"></i>Proven experience in troubleshooting and
                                                supporting business and production issues
                                            </li>
                                            <li><i class="flaticon-tick"></i> OpenShift Container Platform or any other
                                                similar container platforms
                                            </li>
                                            <li><i class="flaticon-tick"></i> Kubeflow, KServe, MLFlow and other ML
                                                related platform experience</li>
                                            <li><i class="flaticon-tick"></i>Data Streaming software like Kafka or
                                                equivalent technologies</li>
                                            <li><i class="flaticon-tick"></i> Experience in concepts and libraries such
                                                as application state management (GetX etc.), Offline Support, App
                                                Security</li>
                                            <li><i class="flaticon-tick"></i> IOT Applications, frameworks and
                                                technologies</li>
                                            <li><i class="flaticon-tick"></i> JAVA skills, RESTful Microservices, Web
                                                Services</li>

                                            <li><i class="flaticon-tick"></i> Play Framework, Spring Framework and other
                                                java related web technologies and frameworks</li>

                                            <li><i class="flaticon-tick"></i> PostgreSQL, MongoDB, Cassandra,
                                                Elasticsearch, OLAP, Redis, Caching technologies and libraries</li>

                                            <li><i class="flaticon-tick"></i> Experience in REST, JSON, AJAX, WebSocket,
                                                API/Services</li>

                                            <li><i class="flaticon-tick"></i> Knowledge of software development
                                                lifecycle and Agile software development
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="about-content careers-content">
                    <div class="job-category">
                        <div class="job-titles">
                            <div>Full Stack Software Engineers</div>

                            <!-- <div>App Developers (iOS, Android, Hybrid), 
                            Sr./Jr. Software Engineers (Full Stack), 
                            Sr./Jr. Quality Assurance Engineers</div> -->
                        </div>
                        <div style="font-size: 20px; color: black;"><b>Software Engineers (Full Stack – React, Java) /
                                Senior
                                Sr. Software Engineers (Full Stack – React, Java)</b></div>
                        <!-- <div class="intern">Internships available</div> -->
                        <ul class="about-list">
                            <li><i class="flaticon-tick"></i> 2 to 6 years’ experience</li>
                            <li><i class="flaticon-tick"></i> Strong knowledge in Software Engineering and Programming
                                principles and practices</li>
                            <li><i class="flaticon-tick"></i> Degree in Software Engineering, Computer Science,
                                Electronics Engineering or Equivalent</li>
                        </ul>

                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="about-content careers-content">
                                    <h4>What you will need</h4>
                                    <div>
                                        <ul class="about-list">
                                            <li>
                                                <i class="flaticon-tick"></i> Hands on relevant years of experience in application development and troubleshooting focusing on either
                                                    <div class="ul-l2">
                                                        <ul class="about-list">
                                                            <li>
                                                            <i class="flaticon-tick"></i>  JEE Application development, Java Microservices, Core JAVA technologies, Spring, Spring Boot
                                                            </li>
                                                            <li><i class="flaticon-tick"></i> React and TypeScript and other frontend frameworks like Angular</li>
                                                        </ul>
                                                    </div>
                                            </li>
                                            <li><i class="flaticon-tick"></i> Hands on experience and thorough knowledge in SQL and/or NoSQL databases
                                            </li>
                                            <li><i class="flaticon-tick"></i>Ability to collaborate with other developers, analysts, architects and project managers to plan, design, develop, test,
                                                and maintain applications</li>
                                            <li><i class="flaticon-tick"></i> Ability to communicate and develop solutions by developing documentation, flowcharts, layouts, diagrams, charts,
                                                code comments, and clear code</li>
                                            <li><i class="flaticon-tick"></i>Maintain high standards of software quality within the team by establishing good practices and habits.</li>
                                            <li><i class="flaticon-tick"></i> Take ownership in driving projects and achieving goals and producing deliverables</li>
                                            <li><i class="flaticon-tick"></i> Comfortable working in a fast-paced, dynamic environment</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="about-content careers-content">
                                    <h4>Having the following skills is a plus</h4>
                                    <div>
                                        <ul class="about-list">
                                            <li>
                                                <i class="flaticon-tick"></i>Proven experience in troubleshooting and supporting business and production issues
                                            </li>
                                            <li><i class="flaticon-tick"></i> Spring boot, Spring Framework, Spring WebFlux, and other java related web technologies and frameworks
                                            </li>
                                            <li><i class="flaticon-tick"></i> Understanding of asynchronous Event-driven systems, Reactive programming, Functional Programming</li>
                                            <li><i class="flaticon-tick"></i>IOT Applications, frameworks and technologies</li>
                                            <li><i class="flaticon-tick"></i> PostgreSQL, MongoDB, Cassandra, Elasticsearch, OLAP, Redis, Caching technologies and libraries</li>
                                            <li><i class="flaticon-tick"></i> Modern web and mobile technologies - Single page web Apps and responsive Apps development</li>
                                            <li><i class="flaticon-tick"></i> Strong Experience in React, Angular, Ionic, TypeScript, JavaScript, HTML5, CSS</li>

                                            <li><i class="flaticon-tick"></i> Strong Experience in REST, JSON, AJAX, WebSocket, API/Services</li>

                                            <li><i class="flaticon-tick"></i> Knowledge of automated testing and test frameworks like Selenium etc.</li>

                                            <li><i class="flaticon-tick"></i> Experience with dev tools like Git, Maven, Gulp, SBT, Jenkins other build/deployment tools and JIRA</li>

                                            <li><i class="flaticon-tick"></i> Unit testing, Performance Testing, and Test-Driven Development
                                            </li>

                                            <li><i class="flaticon-tick"></i> Knowledge of software development lifecycle and Agile software development
                                            </li>

                                            <li><i class="flaticon-tick"></i> Graylog, Grafana and other systems monitoring tools and techniques
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="about-content careers-content">
                    <div class="job-category">
                        <div class="job-titles">
                            <div>Quality Assurance Engineers</div>
                            <div class="intern">INTERNSHIPS / TRAINEES</div>
                            <!-- <div>App Developers (iOS, Android, Hybrid), 
                            Sr./Jr. Software Engineers (Full Stack), 
                            Sr./Jr. Quality Assurance Engineers</div> -->
                        </div>
                        <!-- <div class="intern">Internships available</div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-section sendcv-section ptb-50">
    <div class="container">
        <div class="row align-items-center">
            <div>Please Send Your Resume/CV to <b>info@qbitum.com</b></div>
        </div>
    </div>
</section>



<!--
<section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="820">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Data Analytics</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="150">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Data Management</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="20">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Managed  Analytics</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="120">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Big Data</p>
                </div>
            </div>
        </div>
    </div>
</section>

-->

<!--
<section class="productive-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="productive-content">
                    <span>Let’s Get Started</span>
                    <h3>Are you ready for a better, more productive business </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices Lorem Ipsum is simply dummy text.</p>
                    <div class="productive-btn">
                        <a routerLink="/projects" class="productive-btn">Get Started Project <span></span></a>
                        <a routerLink="/contact" class="productive-btn-one">Contact With Us <span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="productive-image">
                    <img src="assets/img/productive.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
-->

<!--
<section class="testimonial-section ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h3>What Our Client Say</h3>
        </div>
        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Richard Denial</h3>
                    <span>IT Executive</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/2.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Sarah Taylor</h3>
                    <span>Lead Architecure</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Alastair Cook</h3>
                    <span>Marketing Manager</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="testimonial-shape"><img src="assets/img/shape/testimonial-shape-1.png" alt="main-image"></div>
    <div class="testimonial-shape-img1"><img src="assets/img/shape/testimonial-shape-2.png" alt="image"></div>
    <div class="testimonial-shape-img2"><img src="assets/img/shape/testimonial-shape-3.png" alt="image"></div>
</section>
-->

<!--
<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Experts</span>
            <h3>Meet Our Leadership Preparing For Your Success</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-item">
                    <div class="team-image">
                        <img src="assets/img/team/1.jpg" alt="team">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="team-content">
                        <h3>Thomas Edison</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-team-item">
                    <div class="team-image">
                        <img src="assets/img/team/2.jpg" alt="team">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="team-content">
                        <h3>Louis Pasteur</h3>
                        <span>Project Manager</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3">
                <div class="single-team-item">
                    <div class="team-image">
                        <img src="assets/img/team/3.jpg" alt="team">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="team-content">
                        <h3>Pierre Curie</h3>
                        <span>Web Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->
<!--
<section class="partner-section pt-100 pb-70">
    <div class="container">
        <div class="partner-title">
            <span>Customer</span>
            <h3>Discover Customer Successful Stories</h3>
        </div>
        <div class="partner-list">
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/2.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/3.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/4.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/5.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/6.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/7.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/8.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="image"></a>
            </div>
        </div>
    </div>

    <div class="partner-shape"><img src="assets/img/shape/partnar-shape-1.png" alt="main-image"></div>
    <div class="partner-shape-img1"><img src="assets/img/shape/partnar-shape-2.png" alt="image"></div>
</section>

-->

<!--
<section class="subscribe-section ptb-100">
    <div class="container">
        <div class="subscribe-content-area mb-0">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-image">
                        <img src="assets/img/subscribe.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="subscribe-content">
                        <h2>Don’t Miss Our News And Updates</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                    <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                        <button type="submit">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

-->
<div class="main-banner-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 pt-100">
                        <div class="main-banner-content">
                            <div class="mt-xxl-3 mt-xxl-3 mt-lg-3 mt-md-3 mt-sm-3  mt-xs-1">
                                <!-- <h4 style="color: #ffffff;">Data-Centric</h4> -->
                                <h2 style="color: #ffffff;">AI augmented Real-time <br>Industry Data Platform</h2>
                                <br>
                                <p class="web-2x-defult-font" style="line-height: 1.4; font-size: 2rem;"><span
                                        style="color:#F7CD59; font-size: 1.4rem;">How
                                        can AI/XAI and Realtime Streaming data help manufactures enter the world of
                                        Industry 5.0</span>
                                    <br />
                                    <br />
                                    <span style="font-size: 14px;">
                                        Demands of Industry 4.0 and 5.0 can be accommodated with augmenting human tools
                                        and machines with AI to add new capabilities, improve production quality,
                                        efficiency and maintenance. Image and Video Stream processing coupled with Deep
                                        learning, Anomaly Detection and many other AI tech can add significant value to
                                        improve quality inspection expectations. AI augmented analytics over Big data
                                        and Fast data can help improve production KPIs and make near real-time
                                        predictions and recommendations. Anomaly Detection, Predictive Maintenance,
                                        Operational Observability, Production Pattern recognition, Operational
                                        Recommendations, Alerts and Escalations are some of the key areas human work can
                                        be complemented, augmented and automated with Collaborative AI Bots inspired by
                                        Industry 5.0

                                    </span>
                                    <!-- <span style="color:#ffffff;font-size:16px">
                               Digital Twin | Operational Insights | Fast Data (Real-time) | Big Data
                               <br /> Predictive Analytics | ML / AI | IOT | Stream Processing | Cloud Native
                           </span>-->
                                </p>
                            </div>
                            <!--div class="banner-btn">
                                <a class="default-btn-one-2" routerLink="/about">About Us <span></span></a>
                                <a class="default-btn-2" routerLink="/pricing">Contact Us <span></span></a>
                            </div -->
                            <!-- <div style="margin-top: -142px; font-size: 12px; line-height: 20px; color: #4a2282;"
                                class="banner-bottom-tgtext">
                                <span>
                                    AI/XAI | ML | Anomaly Detection | Image and Video Stream Processing | Predictive
                                    Analytics | Recommendations | IOT | Stream Processing | Digital Twin | Operational
                                    Insights | Platform as a Product | Industry Data Platform
                                </span>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <!-- <img src="assets/img/home-two/1.png" class="wow fadeInUp" data-wow-delay="1s" alt="image">
                            <img src="assets/img/home-two/2.png" class="wow fadeInLeft" data-wow-delay="1s" alt="image">
                            <img src="assets/img/home-two/3.png" class="wow fadeInRight" data-wow-delay="1s" alt="image">
                            <img src="assets/img/home-two/4.png" class="wow fadeInUp" data-wow-delay="1s" alt="image">
                            <img src="assets/img/home-two/5.png" class="wow zoomIn" data-wow-delay="1s" alt="image">
                            -->
                            <img src="assets/img/home-two/banner-02.png" class="wow zoomIn" data-wow-delay="1s"
                                alt="image">
                            <img src="assets/img/home-two/banner-02.png" class="wow zoomIn" data-wow-delay="1s"
                                alt="image">
                        </div>
                        <div class="circle-img">
                            <img src="assets/img/home-two/shape.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="creative-shape">
        <div class="engraved header-bottm-txt">
            <h3>Re-thinking Data Driven Decisions with AI and Operational Observability
            </h3>
        </div>
    </div>
    <!-- <div class="shape-dot"><img src="assets/img/main-banner-shape/dot.png" alt="image"></div> -->
</div>
<!-- <section class="process-section pt-40">
    <div class="container">
        <div class="section-title">
            <h3>Qbitum is the one stop shop for your product building</h3>
        </div>
    </div>
</section> -->
<!-- <section class="services-section pb-40 pt-40">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="about-content text-center">
                    <h3>Experienced Multidisciplinary Teams</h3>
                    <p>Modern SaaS products require expertise in many disciplines from Business analysis, <b>Product
                            thinking, Platform Thinking</b>, APIs and Microservices, Software engineering and App (Web
                        and Mobile) UI/UX design to implementation and deployment. Almost every product now needs
                        Analytics, Insights, Alerts and many other <b>data centric features.</b> Further, <b>great
                            products that succeed need advanced skills</b> like Data engineering, CI/CD, Platform
                        engineering, Machine learning, AI, Real-time data, Scaling, Security, Containerization,
                        Anytime-Anywhere Access, offline support and many other areas.
                        <br><br>
                        Your <b>Time-To-Market and Market Opportunity could be greatly affected</b> by experimenting and
                        gaining experience necessary on above capabilities and skills.
                        <br><br>
                        Tech challenges, Could costs and putting together a team with required skills and experience
                        could drive your <b>TOTAL COST sometimes unaffordable.</b>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="software-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="software-image">
                    <img src="assets/img/co-bot.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="software-content">
                    <h2>Inspector Bots</h2>
                    <p class="highlight-para">In the world of Inspections
                        Multiple Industries and Trades put
                        Enormous burden on their employees to do
                        Strenuous, Exhaustive and Tedious Inspections
                        <br>
                        This adds major risks to the business and hinders business potential and scaling
                        <br>
                        It demands employees to do humanly incapable and unsuitable work as their core job
                        Due to lack of solutions and tools both the Business and the Employees suffer
                    </p>
                    <div class="services-btn mt-2">
                        <a routerLink="/services" class="services-btn-one coustom-link">Read More <i
                                class="flaticon-right"></i></a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-section pb-100 pt-100">
    <div class="container">
        <div class="section-title">
            <!-- <span>Testimonial</span> -->
            <h3>What Our Client Say</h3>
        </div>
        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-single-item">
                <!-- <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div> -->
                <div class="client-info text-center">
                    <div class="testimonial-info w-100 text-center">
                        <!-- <span class="tm-org-txt">SolTrim International (Pvt) Ltd</span> -->
                        <div class="w-100 text-center">
                            <img src="assets/img/client/st-logo.png" alt="image" style="margin: auto;">
                        </div>

                        <hr class="solid" style="margin: auto;">
                    </div>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>
                        Printing industry quality inspections are mostly manual. The accuracy,speed, efficiency and the
                        overall cost has been challenged by these manual operations.
                        <br>
                        Manual inspections are tedious and require our quality inspectors to focus on very small prints
                        at a fast pace throughout the day that wears them out very fast.
                        <br>
                        The artificial intelligence (AI) and computer vision based solution for quality inspections that
                        "Qbitum Accumetic" product has featured is something our industry has been waiting for last 20
                        years.
                        <br>
                        Looking at the results of the case studies we have tried so far, we are positive that "Qbitum
                        Accumetic" is a very promising solution.
                    </p>
                </div>
                <div class="client-info text-center mt-2">
                    <div class="testimonial-info w-100 text-center" style="margin: 20px 0;">
                        <!-- <span class="tm-org-txt">SolTrim International (Pvt) Ltd</span> -->
                        <hr class="solid" width="200px" style="margin: auto;">
                        <div class="text-center mb-0" style="font-weight: bold; margin-top: 16px;">Suren Abeyasekera
                        </div>
                        <span class="text-center" style="font-size: .8rem;">Chief Executive Officer</span>
                    </div>
                </div>
            </div>
            <!-- <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/2.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Sarah Taylor</h3>
                    <span>Lead Architecure</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="testimonial-single-item">
                <div class="testimonial-image">
                    <img src="assets/img/client/1.jpg" alt="image">
                </div>
                <div class="testimonial-info">
                    <h3>Alastair Cook</h3>
                    <span>Marketing Manager</span>
                </div>
                <div class="testimonial-content">
                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div> -->
        </div>
    </div>

    <!-- <div class="testimonial-shape"><img src="assets/img/shape/testimonial-shape-1.png" alt="main-image"></div> -->
    <!-- <div class="testimonial-shape-img1"><img src="assets/img/shape/testimonial-shape-2.png" alt="image"></div> -->
    <!-- <div class="testimonial-shape-img2"><img src="assets/img/shape/testimonial-shape-3.png" alt="image"></div> -->
</section>

<section class="software-section pb-100 pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12">
                <div>
                    <img src="assets/img/2-step-prosess.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="software-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="software-content">
                    <!--<span>Data Centric Software Solutions</span>-->
                    <h2>Explore Our Data Services</h2>
                    <p>Data science uses scientific methods, methodical processes, algorithms, software and tools and
                        techniques. Our Platfrom evolves around following core concepts</p>
                    <ul class="features-list">
                        <li><span>Anomaly Detection</span></li>
                        <li><span>Predictive Analytics</span></li>
                        <li><span>Explainable AI (XAI)</span></li>
                        <li><span>Machine Learning</span></li>
                        <li><span>Operational Insights</span></li>
                        <li><span>Data Exploration</span></li>
                        <li><span>Data Engineering</span></li>
                        <li><span>Big Data Analytics</span></li>
                        <li><span>Machine Vision</span></li>
                        <li><span>Deep Learning</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="software-image">
                    <img src="assets/img/explore_data_s.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="about-section pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="software-image text-center">
                    <img src="assets/img/multidisciplinary_teams.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span>About Us</span> -->
                    <h3>Qbitum Industry Data Platform</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> -->
                    <ul class="about-list">
                        <li class="web-defult-font"><i class="flaticon-tick"></i>Improve Quality Inspection accuracy and
                            speed incredibly using AI</li>

                        <li class="web-defult-font"><i class="flaticon-tick"></i>One Platform bundles curated
                            technologies and proven processes</li>

                        <li class="web-defult-font"><i class="flaticon-tick"></i>Use Anomaly Detection, Predictive
                            Maintenance, Recommendations and Pattern recognition</li>

                        <li class="web-defult-font"><i class="flaticon-tick"></i>Operational Observability and Real-time
                            Augmented Analytics</li>

                        <li class="web-defult-font"><i class="flaticon-tick"></i>Empower users with modern UI/UX and
                            Data Driven Decisions with ML/AI
                        </li>


                    </ul>
                    <!-- <div class="about-btn">
                        <a class="default-btn" routerLink="/about">Read More <span></span></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <section class="process-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h3>Multiple ways to co-create and collaborate</h3>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/process/1.png" alt="image">
                    </div>
                    <div class="process-content">
                        <h3>Start with a Bright Idea</h3>
                        <p>Bring your Own Bright Idea. Support it with our knowledge, passion and experience in building
                            SUCCESSFUL Products</p>
                        <div class="process-btn">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/process/2.png" alt="image">
                    </div>
                    <div class="process-content">
                        <h3>Shape Up a Product</h3>
                        <p>Take the product to NEXT level using Product THINKING, Modern UI / UX, Apps Technologies and
                            Data Science </p>
                        <div class="process-btn">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/process/3.png" alt="image">
                    </div>
                    <div class="process-content">
                        <h3>Take Care and Control</h3>
                        <p>Monitor, Manage and Control the Development, Operations and Product life cycle more
                            effectively
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- <section class="software-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-lg-last">
                <div class="software-content">
                    <h2>Software Development Services</h2>
                    <p>Vast breadth of Cloud technologies and associated continuous deployments and platform engineering makes it hard to cover the breadth and depth of it. Our team has skills and experience around following core concept</p>
                    <ul class="features-list">
                        <li><span>Product Development</span></li>
                        <li><span>Hybrid Apps</span></li>
                        <li><span>Single Page Web Apps</span></li>
                        <li><span>REST APIs</span></li>
                        <li><span>Containerization</span></li>
                        <li><span>Stream Processing</span></li>
                        <li><span>Microservices</span></li>
                        <li><span>Cloud Native systems</span></li>
                        <li><span>Event Driven systems</span></li>
                        <li><span>Monitoring</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-lg-first">
                <div class="software-image">
                    <img src="assets/img/software_dev_s.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--
<section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h3>Explore Our Data Services</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-big-data"></i>
                    </div>
                    <h3>Big Data</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data-analytics"></i>
                    </div>
                    <h3>Data Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-document"></i>
                    </div>
                    <h3>Managed Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-chart"></i>
                    </div>
                    <h3>Business Intelligence</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-science"></i>
                    </div>
                    <h3>Data Science</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data-management"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->

<!-- <section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h3>Fast Data Centric Software Solutions</h3>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/1_problem.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Problem</h3>
                    <p>Understanding the problem</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/2_domain.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Domain</h3>
                    <p>Understanding the domain</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/3_capture.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Capture</h3>
                    <p>Data Capturing via software and devices</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/4_wrangling.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Wrangling,Data Capturing</h3>
                    <p>Data Wrangling</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/5_exploration.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Exploration</h3>
                    <p>Exploration and ML, Wrangling,Data Capturing</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/6_pipeline.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Pipeline</h3>
                    <p>Data Pipeline automation</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/7_visualise.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Visualise</h3>
                    <p>Visualise Fast Data Analytics</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/8_insights.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Insights</h3>
                    <p>Insights and Recommendations</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/9_alerts.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Alerts</h3>
                    <p>Trigger Alerts and Actions based on data</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/10_access.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Access</h3>
                    <p>Access anywhere anytime</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="text-center">
                    <img src="assets/img/home-one/11_security.png">
                </div>
                <div class="text-center mb-5">
                    <h3>Security</h3>
                    <p>Data security</p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="process-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Working Process</span>
            <h3>Software Testing Process</h3>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/process/1.png" alt="image">
                    </div>
                    <div class="process-content">
                        <h3>Planning</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                        <div class="process-btn">
                            <a routerLink="/projects-details" class="process-btn-one">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/process/2.png" alt="image">
                    </div>
                    <div class="process-content">
                        <h3>Design & Execution</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                        <div class="process-btn">
                            <a routerLink="/projects-details" class="process-btn-one">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="process-item">
                    <div class="process-image">
                        <img src="assets/img/process/3.png" alt="image">
                    </div>
                    <div class="process-content">
                        <h3>Analytics Projects</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                        <div class="process-btn">
                            <a routerLink="/projects-details" class="process-btn-one">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="software-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="software-content">
                    <span>Our Working Process</span>
                    <h2>Software Developement Services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices Lorem Ipsum is simply dummy text.</p>
                    <ul class="features-list">
                        <li><span>Software Development</span></li>
                        <li><span>Automatic Optimization</span></li>
                        <li><span>Applicatication Services</span></li>
                        <li><span>IT Security Services</span></li>
                        <li><span>Data Analytics</span></li>
                        <li><span>System Integration Service</span></li>
                        <li><span>Help Desk Services</span></li>
                        <li><span>API Integration Service</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="software-image">
                    <img src="assets/img/software.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h3>Expolre Our Data Services</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-big-data"></i>
                    </div>
                    <h3>Big Data</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data-analytics"></i>
                    </div>
                    <h3>Data Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-document"></i>
                    </div>
                    <h3>Managed Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-chart"></i>
                    </div>
                    <h3>Business Intelligence</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-science"></i>
                    </div>
                    <h3>Data Science</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-data-management"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum </p>
                    <div class="services-btn">
                        <a routerLink="/services-details" class="services-btn-one">Read More <i class="flaticon-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h3>Focused on Actionable Insights</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <ul class="about-list">
                        <li><i class="flaticon-tick"></i> Professional Consultancy Service</li>
                        <li><i class="flaticon-tick"></i> 24/7 Support Center</li>
                        <li><i class="flaticon-tick"></i> Custom Service & Operation</li>
                        <li><i class="flaticon-tick"></i> Advanced Advisory Team</li>
                    </ul>
                    <div class="about-btn">
                        <a class="default-btn" routerLink="/about">Read More <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="fun-facts-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="820">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Data Analytics</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="150">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Data Management</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="20">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Managed  Analytics</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="120">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Big Data</p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="productive-section pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="productive-content">
                    <span>Let’s Get Started</span>
                    <h3>Are you ready for a better, more productive business </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices Lorem Ipsum is simply dummy text.</p>
                    <div class="productive-btn">
                        <a routerLink="/projects" class="productive-btn">Get Started Project<span></span></a>
                        <a routerLink="/contact" class="productive-btn-one">Contact With Us<span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="productive-image">
                    <img src="assets/img/productive.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Blog</span>
            <h3>Recent Blog</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/3.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 9 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 4 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Startup Marketing Solution for Business Owner</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/4.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 8 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 4 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Web Developement Best Work in Future World</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="blog-item">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/5.png" alt="image">
                        </a>
                    </div>
                    <div class="single-blog-item">
                        <ul class="blog-list">
                            <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                            <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 7 Dec 2020</a></li>
                            <li><i class="far fa-comments"></i> 3 Comments</li>
                        </ul>
                        <div class="blog-content">
                            <h3><a routerLink="/blog-details">Creative solutions to improve your business!</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                            <div class="blog-btn">
                                <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="partner-section pt-100 pb-70">
    <div class="container">
        <div class="partner-title">
            <span>Customer</span>
            <h3>Discover Customer Successful Stories</h3>
        </div>
        <div class="partner-list">
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/2.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/3.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/4.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/5.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/6.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/7.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/8.png" alt="image"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/partner"><img src="assets/img/partner/1.png" alt="image"></a>
            </div>
        </div>
    </div>

    <div class="partner-shape"><img src="assets/img/shape/partnar-shape-1.png" alt="main-image"></div>
    <div class="partner-shape-img1"><img src="assets/img/shape/partnar-shape-2.png" alt="image"></div>
</section> -->

<!-- <app-subscribe></app-subscribe> -->
<div class="page-title-area item-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog Right Sidebar</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog Right Sidebar</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="blog-item">
                            <div class="blog-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/3.png" alt="image">
                                </a>
                            </div>
                            <div class="single-blog-item">
                                <ul class="blog-list">
                                    <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                                    <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 9 Dec 2020</a></li>
                                    <li><i class="far fa-comments"></i> 4 Comments</li>
                                </ul>
                                <div class="blog-content">
                                    <h3><a routerLink="/blog-details">Startup Marketing Solution for Business Owner</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                                    <div class="blog-btn">
                                        <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="blog-item">
                            <div class="blog-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/4.png" alt="image">
                                </a>
                            </div>
                            <div class="single-blog-item">
                                <ul class="blog-list">
                                    <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                                    <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 8 Dec 2020</a></li>
                                    <li><i class="far fa-comments"></i> 4 Comments</li>
                                </ul>
                                <div class="blog-content">
                                    <h3><a routerLink="/blog-details">Web Developement Best Work in Future World</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                                    <div class="blog-btn">
                                        <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="blog-item">
                            <div class="blog-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/5.png" alt="image">
                                </a>
                            </div>
                            <div class="single-blog-item">
                                <ul class="blog-list">
                                    <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                                    <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 7 Dec 2020</a></li>
                                    <li><i class="far fa-comments"></i> 3 Comments</li>
                                </ul>
                                <div class="blog-content">
                                    <h3><a routerLink="/blog-details">Creative solutions to improve your business!</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                                    <div class="blog-btn">
                                        <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="blog-item">
                            <div class="blog-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/4.png" alt="image"></a>
                            </div>
                            <div class="single-blog-item">
                                <ul class="blog-list">
                                    <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                                    <li><a routerLink="/"><i class="fas fa-calendar-week"></i>7 Dec, 2020</a></li>
                                    <li><i class="far fa-comments"></i> 3 Comments</li>
                                </ul>
                                <div class="blog-content">
                                    <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                                    <div class="blog-btn">
                                        <a routerLink="blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>  
                        </div> 
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="blog-item">
                            <div class="blog-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/5.png" alt="image"></a>
                            </div>
                            <div class="single-blog-item">
                                <ul class="blog-list">
                                    <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                                    <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 7 Dec, 2020</a></li>
                                    <li><i class="far fa-comments"></i> 3 Comments</li>
                                </ul>
                                <div class="blog-content">
                                    <h3><a routerLink="/blog-details">Instagram Feed Add To Your WordPress Site</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                                    <div class="blog-btn">
                                        <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>  
                        </div> 
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="blog-item">
                            <div class="blog-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/6.png" alt="image"></a>
                            </div>
        
                            <div class="single-blog-item">
                                <ul class="blog-list">
                                    <li><a routerLink="/"><i class="fa fa-user-alt"></i> Admin</a></li>
                                    <li><a routerLink="/"><i class="fas fa-calendar-week"></i> 7 Dec, 2020</a></li>
                                    <li><i class="far fa-comments"></i> 3 Comments</li>
                                </ul>
                                <div class="blog-content">
                                    <h3><a routerLink="/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                                    <div class="blog-btn">
                                        <div class="blog-btn">
                                            <a routerLink="/blog-details" class="blog-btn-one">Read More <i class="fa fa-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div> 
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/blog-grid" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                            <a routerLink="/blog-grid" class="page-numbers">1</a>
                            <span class="page-numbers current" aria-current="page">2</span>
                            <a routerLink="/blog-grid" class="page-numbers">3</a>
                            <a routerLink="/blog-grid" class="page-numbers">4</a>
                            <a routerLink="/blog-grid" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form search-top">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </section>
                    <section class="widget widget_plamb_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <time class="2020-06-30">June 10, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <time class="2020-06-30">June 21, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h4>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <time class="2020-06-30">June 30, 2020</time>
                                <h4 class="title usmall"><a routerLink="/blog-details">How To Create A Responsive Popup Gallery?</a></h4>
                            </div>
                        </article>
                    </section>
                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/">Business</a></li>
                            <li><a routerLink="/">Privacy</a></li>
                            <li><a routerLink="/">Technology</a></li>
                            <li><a routerLink="/">Tips</a></li>
                            <li><a routerLink="/">Uncategorized</a></li>
                        </ul>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="tagcloud section-bottom">
                            <a routerLink="/">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Plamb <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips<span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>
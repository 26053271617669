<div class="page-title-area item-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Expolre Our Data Services</h3>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                        <div class="features-text">
                            <h4>Managed Analytics</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                        </div>
                        <div class="features-text">
                            <h4>Business Intelligence</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="image-sliders owl-carousel owl-theme">
                        <div class="services-details-image">
                            <img src="assets/img/services-details/1.jpg" alt="image">
                        </div>
                        <div class="services-details-image">
                            <img src="assets/img/services-details/2.jpg" alt="image">
                        </div>
                        <div class="services-details-image">
                            <img src="assets/img/services-details/3.jpg" alt="image">
                        </div>
                        <div class="services-details-image">
                            <img src="assets/img/services-details/4.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="image-sliders owl-carousel owl-theme">
                        <div class="services-details-image">
                            <img src="assets/img/services-details/2.jpg" alt="image">
                        </div>
                        <div class="services-details-image">
                            <img src="assets/img/services-details/4.jpg" alt="image">
                        </div>
                        <div class="services-details-image">
                            <img src="assets/img/services-details/3.jpg" alt="image">
                        </div>
                        <div class="services-details-image">
                            <img src="assets/img/services-details/1.jpg" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>We Provide Useful Services</h3>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                        <div class="features-text">
                            <h4>Managed Analytics</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                        </div>
                        <div class="features-text">
                            <h4>Business Intelligence</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>
</section>